
import robonomics from "../../../robonomics";
import { fromUnit } from "../../../utils/tools";

export default {
  data() {
    return {
      unsubscribeBalance: null,
      balance: "0",
      unit: "XRT"
    };
  },
  created() {
    this.unit = robonomics.api.registry.chainTokens[0];
  },
  computed: {
    balanceFormat() {
      return Number(
        fromUnit(this.balance, robonomics.api.registry.chainDecimals[0])
      );
    }
  },
  methods: {
    async handlerAddress() {
      if (this.unsubscribeBalance) {
        this.unsubscribeBalance();
      }
      const account = this.$store.state.robonomicsUIvue.polkadot.accounts.find(
        (item) =>
          item.address === this.$store.state.robonomicsUIvue.polkadot.address
      );
      await robonomics.accountManager.setAccount(
        account,
        this.$store.state.robonomicsUIvue.polkadot.extensionObj
      );
      this.unsubscribeBalance = await robonomics.account.getBalance(
        account.address,
        (r) => {
          this.balance = r.free.sub(r.feeFrozen);
        }
      );
    }
  }
};
