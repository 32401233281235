
import { onUnmounted, watchEffect, ref, watch } from "vue";
import { useAccount } from "@/hooks/useAccount";
import { useSend } from "@/hooks/useSend";
import robonomics from "../../robonomics";
import { Keyring } from "@polkadot/keyring";
import { u8aToString, u8aToHex, hexToU8a } from "@polkadot/util";
import {
  decodeAddress,
  encodeAddress,
  validateAddress
} from "@polkadot/util-crypto";

export default {
  setup() {
    const subscriptionOwner = ref("");
    const adminHA = ref("");
    const hasSubscription = ref(false);
    const passwordsItems = ref(null);

    const { account: sender, unsubscribe } = useAccount();
    onUnmounted(() => {
      unsubscribe();
    });

    const checkHasSubscription = async (sender, owner) => {
      try {
        validateAddress(owner);
      } catch (_) {
        hasSubscription.value = false;
        return;
      }
      const devices = await robonomics.rws.getDevices(owner);
      if (devices.includes(sender)) {
        hasSubscription.value = true;
        return;
      }
      hasSubscription.value = false;
    };

    watchEffect(() => {
      checkHasSubscription(sender.value, subscriptionOwner.value);
    });

    const findPassword = async (sender) => {
      let result = [];
      const log = await robonomics.datalog.read(sender);
      for (const item of log) {
        try {
          const json = JSON.parse(item[1].toHuman());
          if (json.admin && json.user && json.ha && json.ha === adminHA.value) {
            result.push({
              created: new Date(item[0].toNumber()).toLocaleString(),
              pass: json.user.substring(0, 5) + "...",
              encrypted: json.user,
              isShow: false,
              actions: ""
            });
          }
          // eslint-disable-next-line no-empty
        } catch (_) {}
      }
      passwordsItems.value = result;
    };

    watchEffect(() => {
      findPassword(sender.value);
    });

    watch(adminHA, () => {
      try {
        validateAddress(adminHA.value);
        findPassword(sender.value);
        // eslint-disable-next-line no-empty
      } catch (_) {}
    });

    const tx = useSend();

    return {
      sender,
      subscriptionOwner,
      adminHA,
      hasSubscription,
      passwordsItems,
      tx
    };
  },

  data() {
    return {
      adminHAH: "",
      uri: "",
      passwordInput: "",
      passwordRepeatInput: "",
      passwordForAdmin: "",
      passwordForRecovery: "",
      passwordsFields: [
        {
          key: "created",
          label: "Created"
        },
        {
          key: "pass",
          label: "Password"
        },
        {
          key: "actions",
          label: "Actions",
          align: "right"
        }
      ],
      showPasswordPressedAll: false
    };
  },
  computed: {
    account() {
      if (this.uri) {
        try {
          const k = new Keyring();
          return k.addFromUri(this.uri, {}, "ed25519");
        } catch (error) {
          console.log(error);
        }
      }
      return null;
    },
    validateUri() {
      if (
        this.account &&
        this.sender &&
        encodeAddress(this.sender) === encodeAddress(this.account.address)
      ) {
        return true;
      }
      return false;
    },
    canRegistration() {
      if (this.validateUri && this.hasSubscription && this.adminHA) {
        return true;
      }
      return false;
    },
    checkPasswordsMatch() {
      return this.passwordInput === this.passwordRepeatInput;
    }
  },
  watch: {
    passwordInput() {
      this.generatePasswords();
    },
    passwordRepeatInput() {
      this.generatePasswords();
    },
    showPasswordPressedAll() {
      for (const key in this.passwordsItems) {
        this.recoveryPassword(key, !this.showPasswordPressedAll);
      }
    }
  },
  methods: {
    async savePassword() {
      const call = await robonomics.datalog.write(
        JSON.stringify({
          subscription: this.subscriptionOwner,
          ha: this.adminHA,
          admin: this.passwordForAdmin,
          user: this.passwordForRecovery
        })
      );
      await this.tx.send(call, this.subscriptionOwner);
    },
    generatePasswords() {
      if (this.checkPasswordsMatch) {
        this.passwordForRecovery = this.encrypt(
          this.passwordInput,
          this.account.address
        );
        this.passwordForAdmin = this.encrypt(this.passwordInput, this.adminHA);
      } else {
        this.passwordForRecovery = "";
        this.passwordForAdmin = "";
      }
    },
    recoveryPassword(index, force = undefined) {
      if (force === undefined) {
        force = this.passwordsItems[index].isShow;
      }

      if (!force) {
        this.passwordsItems[index].pass = this.decrypt(
          this.passwordsItems[index].encrypted,
          this.account.address
        );
        this.passwordsItems[index].isShow = true;
      } else {
        this.passwordsItems[index].pass =
          this.passwordsItems[index].encrypted.substring(0, 5) + "...";
        this.passwordsItems[index].isShow = false;
      }
    },
    decrypt(hex, address) {
      const decryptMessage = this.account.decryptMessage(
        hexToU8a(hex),
        u8aToHex(decodeAddress(address))
      );
      return u8aToString(decryptMessage);
    },
    encrypt(password, address) {
      const decryptMessage = this.account.encryptMessage(
        password,
        u8aToHex(decodeAddress(address))
      );
      return u8aToHex(decryptMessage);
    }
  }
};
