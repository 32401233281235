
import { useAccount } from "@/hooks/useAccount";
import { useSubscription } from "@/hooks/useSubscription";
import { onUnmounted } from "vue";
import ManageSubscription from "./ManageSubscription.vue";
import NotSubscription from "./NotSubscription.vue";
import ReactivateSubscription from "./ReactivateSubscription.vue";
// import Statistic from "./Statistic.vue";
import robonomics from "../../../robonomics";

export default {
  components: {
    NotSubscription,
    ManageSubscription,
    ReactivateSubscription
    // Statistic
  },
  setup() {
    const { account, unsubscribe } = useAccount();

    onUnmounted(() => {
      unsubscribe();
      if (unsubscribeBlock) {
        unsubscribeBlock();
      }
    });

    const subscription = useSubscription(account);

    let unsubscribeBlock;
    const updateBlock = async () => {
      unsubscribeBlock = await robonomics.onBlock(async () => {
        subscription.loadLedger();
      });
    };
    updateBlock();

    return {
      account,
      subscription
    };
  },
  watch: {
    subscription() {
      console.log(this.subscription);
    }
  }
};
