
import LayoutMain from "./layouts/Main.vue";
import robonomics from "./robonomics";

export default {
  components: { LayoutMain },
  data() {
    return {
      isReady: false
    };
  },
  async created() {
    document.documentElement.setAttribute("lang", "en");
    await robonomics.run();
    this.isReady = true;
  }
};
