
import Account from "./sidebar-right/Account.vue";
import Ipfs from "./sidebar-right/Ipfs.vue";

export default {
  components: {
    Account,
    Ipfs
  }
};
