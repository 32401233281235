
import { defineComponent } from "vue";

export default defineComponent({
  name: "layoutHeader",

  props: {
    items: {
      type: Object
    }
  },

  computed: {
    isMainPage() {
      return this.$route.name === "dashboard";
    }
  }
});
