
import { useAccount } from "@/hooks/useAccount";
import { useSend } from "@/hooks/useSend";
import { useSubscription } from "@/hooks/useSubscription";
import { bnToBn } from "@polkadot/util";
import { onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import robonomics from "../../robonomics";
import { fromUnit, toUnit } from "../../utils/tools";

export default {
  setup() {
    const balance = ref(0);
    const unsubscribeBalance = ref(null);
    const { account, unsubscribe } = useAccount();

    onUnmounted(() => {
      unsubscribe();
      unsubscribeBalance.value();
    });

    watch(
      account,
      async () => {
        if (unsubscribeBalance.value) {
          unsubscribeBalance.value();
        }
        unsubscribeBalance.value = await robonomics.account.getBalance(
          account.value,
          (r) => {
            balance.value = r.free.sub(r.feeFrozen);
          }
        );
      },
      { immediate: true }
    );

    const subscription = useSubscription(account);
    const router = useRouter();

    watch(
      subscription.rawData,
      (newValue, oldValue) => {
        if (oldValue === undefined) {
          return;
        }
        if (newValue !== null && subscription.isActive.value) {
          tx.process.value = false;
          router.push({ name: "subscription-devices" });
        }
      },
      { immediate: true }
    );

    const price = ref(0);
    (async () => {
      const minimalBid = await robonomics.rws.getMinimalBid();
      price.value = fromUnit(
        minimalBid.add(bnToBn(1)),
        robonomics.api.registry.chainDecimals
      );
    })();

    const tx = useSend();
    const bid = async () => {
      const call = await robonomics.rws.bid(
        Number(await robonomics.rws.getFirtsFreeAuction()),
        Number(toUnit(price.value, robonomics.api.registry.chainDecimals))
      );
      await tx.send(call);
      tx.process.value = true;
    };

    return {
      account,
      subscription,
      balance,
      price,
      tx,
      bid
    };
  },

  data() {
    return {
      plan: "1 month",
      freeAuctions: 0,
      isLedger: false,
      unsubscribeBlock: null
    };
  },
  computed: {
    canBid() {
      return this.freeAuctions > 0;
    },
    isDisabled() {
      return this.tx.process.value || this.balance <= 0 || !this.canBid;
    }
  },
  async created() {
    const freeAuctions = await robonomics.rws.getFreeAuctions();
    this.freeAuctions = freeAuctions.length;
    this.unsubscribeBlock = await robonomics.onBlock(async () => {
      const freeAuctions = await robonomics.rws.getFreeAuctions();
      this.freeAuctions = freeAuctions.length;
      this.subscription.loadLedger();
    });
  },
  unmounted() {
    if (this.unsubscribeBlock) {
      this.unsubscribeBlock();
    }
  }
};
