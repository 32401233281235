
import { useAccount } from "@/hooks/useAccount";
import { storage } from "@/hooks/useDevices";
import { useSend } from "@/hooks/useSend";
import { useSubscription } from "@/hooks/useSubscription";
import { checkAddress } from "@polkadot/util-crypto";
import { onUnmounted, watch } from "vue";
import { useRouter } from "vue-router";
import robonomics from "../../robonomics";
import RelatedServices from "./RelatedServices.vue";

export default {
  components: { RelatedServices },
  setup() {
    const { account: owner, unsubscribe } = useAccount();
    onUnmounted(() => {
      unsubscribe();
    });

    const subscription = useSubscription(owner);
    const router = useRouter();

    watch(
      subscription.rawData,
      (newValue, oldValue) => {
        if (oldValue === undefined) {
          return;
        }
        if (newValue === null || !subscription.isActive.value) {
          router.push({ name: "subscription-bid" });
        }
      },
      { immediate: true }
    );

    const tx = useSend();
    const save = async (devices) => {
      const call = await robonomics.rws.setDevices(
        devices.map((item) => item.address)
      );
      await tx.send(call);
      storage.addItem(owner.value, devices);
      await subscription.loadDevices();
    };

    return {
      owner,
      subscription,
      devices: subscription.devices,
      tx,
      save
    };
  },

  data() {
    return {
      tmpDevices: [],
      process: false,
      newDeviceName: "",
      newDeviceAddress: "",
      itemKey: 1
    };
  },
  watch: {
    devices() {
      this.newDeviceName = `Account-${this.maxIndex(this.devices) + 1}`;
      this.tmpDevices = [...this.devices];
    }
  },
  computed: {
    hasChanged() {
      function diff(a, b) {
        return [
          ...a.filter((x) => !b.includes(x)),
          ...b.filter((x) => !a.includes(x))
        ];
      }
      if (
        diff(
          this.tmpDevices.map((a) => a.address),
          this.devices.map((a) => a.address)
        ).length
      ) {
        return true;
      }
      return false;
    },
    isAddressExists() {
      if (
        this.devices.findIndex(
          (item) => item.address === this.newDeviceAddress
        ) < 0
      ) {
        return false;
      }
      return true;
    },
    validNewAddress() {
      if (
        this.newDeviceAddress &&
        this.newDeviceAddress.length === 48 &&
        checkAddress(
          this.newDeviceAddress,
          robonomics.api.registry.chainSS58
        )[0]
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    maxIndex(list) {
      let maxIndex = 0;
      if (list.length > 0) {
        const asd = list.map((a) => Number(a.name.replaceAll(/[^0-9]/gi, "")));
        let max = Math.max(...asd);
        if (max > 0) {
          maxIndex = max;
        }
      }
      return maxIndex;
    },
    async saveToChain() {
      this.process = true;
      try {
        await this.save(this.tmpDevices);
      } catch (error) {
        console.log(error);
      }
      this.process = false;
    },
    async add(addStarted, addStatus) {
      addStarted();
      if (!this.validNewAddress) {
        addStatus(false, "Check the address, incorrect format");
        return;
      }
      if (this.isAddressExists) {
        addStatus(false, "This address already exists");
        return;
      }
      this.tmpDevices.push({
        name: this.newDeviceName,
        address: this.newDeviceAddress
      });
      this.newDeviceName = `Account-${this.maxIndex(this.tmpDevices) + 1}`;
      this.newDeviceAddress = "";
      addStatus(true);
      this.itemKey += 1;
    },
    async remove(device) {
      this.tmpDevices = this.tmpDevices.filter(
        (item) => item.address !== device
      );
      this.newDeviceName = `Account-${this.maxIndex(this.tmpDevices) + 1}`;
    },
    editName(editStarted, editStatus) {
      editStarted();
      storage.addItem(this.owner, this.devices);
      this.newDeviceName = `Account-${this.maxIndex(this.tmpDevices) + 1}`;
      editStatus(true);
    }
  }
};
