
import { ref } from "vue";
import { Keyring } from "@polkadot/keyring";
import { encodeAddress } from "@polkadot/util-crypto";

export default {
  setup() {
    const controller = ref("");
    const seed = ref("");

    return {
      controller,
      seed
    };
  },
  emits: ["controller"],
  computed: {
    controllerAccount() {
      if (this.seed) {
        try {
          const k = new Keyring();
          return k.addFromUri(this.seed, {}, "ed25519");
        } catch (error) {
          console.log(error);
        }
      }
      return null;
    },
    validateUri() {
      if (
        this.controllerAccount &&
        this.controller &&
        encodeAddress(this.controller) ===
          encodeAddress(this.controllerAccount.address)
      ) {
        return true;
      }
      return false;
    }
  },
  watch: {
    validateUri: {
      immediate: true,
      handler() {
        this.$emit("controller", this.controllerAccount);
      }
    }
  }
};
