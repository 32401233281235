
import { u8aToString } from "@polkadot/util";
import { encodeAddress } from "@polkadot/util-crypto";
import { cancelCatFile, catFile } from "../../plugins/ipfs";
import robonomics from "../../robonomics";

export default {
  props: ["controller"],
  emits: ["telemetry"],
  data() {
    return {
      gateways: [
        "other",
        "https://ipfs.io/",
        "https://gateway.ipfs.io/",
        "https://cloudflare-ipfs.com/",
        "https://cf-ipfs.com/",
        "https://ipfs-gateway.cloud/",
        "https://jorropo.net/",
        "https://gateway.pinata.cloud/"
      ],
      selectedGateway: "https://ipfs.io/",
      gateway: "https://ipfs.io/",
      log: [],
      selected: ""
    };
  },
  created() {
    this.read();
  },
  watch: {
    controller() {
      this.read();
    },
    selectedGateway() {
      if (this.selectedGateway === "other") {
        this.gateway = "";
      } else {
        this.gateway = this.selectedGateway;
      }
    }
  },
  computed: {
    canDownload() {
      return (
        this.selectedIndex < 0 ||
        !this.log[this.selectedIndex].hash ||
        this.log[this.selectedIndex].load ||
        !!this.log[this.selectedIndex].data
      );
    },
    dates() {
      return this.log.map((item) => item.date);
    },
    ids() {
      return this.log.map((item) => item.id);
    },
    selectedIndex() {
      return this.log.findIndex((item) => item.id === this.selected);
    }
  },
  methods: {
    async read() {
      if (!this.controller) {
        this.log = [];
        this.selected = "";
        return;
      }

      const log = await robonomics.datalog.read(
        encodeAddress(this.controller.address, 32)
      );
      this.log = log.reverse().map((item, i) => {
        let hash = item[1].toHuman();
        let data = null;
        if (hash.substring(0, 2) === "Qm") {
          data = null;
        } else {
          try {
            data = JSON.parse(hash);
          } catch (_) {
            data = hash;
          }
          hash = null;
        }
        return {
          id: i + 1,
          date: new Date(item[0].toNumber()).toLocaleString(),
          hash: hash,
          data: data,
          load: false,
          error: false
        };
      });
      if (this.log.length) {
        this.selected = this.log[0].id;
      }
    },
    async download() {
      if (this.selectedIndex < 0) {
        return;
      }
      if (this.log[this.selectedIndex].data) {
        this.$emit("telemetry", this.log[this.selectedIndex].data);
        return;
      }
      if (!this.log[this.selectedIndex].hash) {
        return;
      }
      this.log[this.selectedIndex].error = false;
      this.log[this.selectedIndex].load = true;
      let result;
      try {
        result = await catFile(
          this.log[this.selectedIndex].hash,
          this.gateway,
          { timeout: 15000 },
          2
        );
      } catch (error) {
        console.log(error);
        this.log[this.selectedIndex].load = false;
        this.log[this.selectedIndex].error =
          "Data fetch error. Try changing ipfs gateway.";
      }
      if (result) {
        console.log(result);
        try {
          const data = this.decrypt(result);
          if (data) {
            this.log[this.selectedIndex].data = data;
          } else {
            this.log[this.selectedIndex].data = result;
          }
          this.log[this.selectedIndex].load = false;
        } catch (error) {
          console.log(error);
          this.log[this.selectedIndex].load = false;
          this.log[this.selectedIndex].error = "Data decryption error.";
        }
      } else {
        this.log[this.selectedIndex].load = false;
      }
      this.$emit("telemetry", this.log[this.selectedIndex].data);
    },
    cancelDownload() {
      if (this.selectedIndex < 0) {
        return;
      }
      cancelCatFile();
      this.log[this.selectedIndex].load = false;
    },
    decrypt(encryptMessage) {
      const decryptMessage = this.controller.decryptMessage(
        encryptMessage,
        this.controller.publicKey
      );
      return u8aToString(decryptMessage);
    }
  }
};
