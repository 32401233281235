
export default {
  data() {
    return {
      status: false,
      isOnline: false
    };
  },
  mounted() {
    this.getIpfsNodeInfo();
  },
  methods: {
    async getIpfsNodeInfo() {
      try {
        const ipfs = await this.$ipfs;
        this.status = true;
        this.isOnline = ipfs.isOnline();
      } catch (err) {
        console.log(err);
        this.status = false;
      }
    }
  }
};
