
import { fromUnit } from "../../../utils/tools";
import { bnToBn } from "@polkadot/util";
import robonomics from "../../../robonomics";

export default {
  data() {
    return {
      price: 0,
      freeAuctions: 0,
      unsubscribeBlock: null
    };
  },
  computed: {
    canBid() {
      return this.freeAuctions > 0;
    }
  },
  async created() {
    const minimalBid = await robonomics.rws.getMinimalBid();
    this.price = fromUnit(
      minimalBid.add(bnToBn(1)),
      robonomics.api.registry.chainDecimals
    );

    const freeAuctions = await robonomics.rws.getFreeAuctions();
    this.freeAuctions = freeAuctions.length;
    this.unsubscribeBlock = await robonomics.onBlock(async () => {
      const freeAuctions = await robonomics.rws.getFreeAuctions();
      this.freeAuctions = freeAuctions.length;
    });
  },
  unmounted() {
    if (this.unsubscribeBlock) {
      this.unsubscribeBlock();
    }
  }
};
