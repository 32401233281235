
import Overview from "./Overview.vue";
import Subscription from "./Subscription.vue";
import GetTelemetry from "./GetTelemetry.vue";
import ViewTelemetry from "./ViewTelemetry.vue";

export default {
  components: { Overview, Subscription, GetTelemetry, ViewTelemetry },
  data() {
    return {
      controller: null,
      telemetry: null
    };
  }
};
