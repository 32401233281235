
import FileSaver from "file-saver";

export default {
  props: ["telemetry"],
  methods: {
    save() {
      var blob = new Blob([this.telemetry], {
        type: "text/plain;charset=utf-8"
      });
      FileSaver.saveAs(blob, "data.json");
    }
  }
};
